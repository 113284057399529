import "./App.css";
import { Container, Grid, Divider, Alert } from "@mui/material";
import { Outlet } from "react-router-dom";
import Logo from "./MainLogo.svg";

function App() {
  return (
    <Container
      sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Grid container alignItems="center" direction="column">
        <Grid item xs={12}>
          <img src={Logo} className="logo" alt="FindMyBabyFormula.com" />
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Alert severity="error">
          After hosting the site for over 2 years, FindMyBabyFormula will stop
          on 1/1/2024. I hope this website has brought you as much happyness as
          it brought me. Stay safe!
        </Alert>
        <Outlet />
      </Grid>
    </Container>
  );
}

export default App;
