import React from 'react'
import { Grid, Paper } from "@mui/material";
import WalmartLogo from '../assets/walmart_logo.png';
import CVSLogo from '../assets/cvs_logo.png';
import WalgreensLogo from '../assets/walgreens_logo.png';
import TargetLogo from '../assets/target_logo.png';
import AmazonLogo from '../assets/amazon_logo.png';
import CostcoLogo from '../assets/costco_logo.png';
import BuyBuyBabyLogo from '../assets/buybuybaby_logo.png';
import KrogerLogo from '../assets/kroger_logo.png';
import MeijerLogo from '../assets/meijer_logo.png';
import SimilacLogo from '../assets/similac_logo.png';
import SamsClubLogo from '../assets/sams_club_logo.png';

function DisplayStore(storeName) {
    var imageUrl = "";
    var displayName = "";
    if (storeName === "AMAZON") {
        imageUrl = AmazonLogo;
        displayName = "Amazon";
    } else if (storeName === "CVS") {
        imageUrl = CVSLogo;
        displayName = "CVS";
    } else if (storeName === "WALGREENS") {
        imageUrl = WalgreensLogo;
        displayName = "Walgreens";
    } else if (storeName === "WALMART") {
        imageUrl = WalmartLogo
        displayName = "Walmart";
    } else if (storeName === "TARGET") {
        imageUrl = TargetLogo;
        displayName = "Target";
    } else if (storeName === "COSTCO") {
        imageUrl = CostcoLogo;
        displayName = "Costco";
    } else if (storeName === "BUY_BUY_BABY") {
        imageUrl = BuyBuyBabyLogo;
        displayName = "BuyBuyBaby";
    } else if (storeName === "KROGER") {
        imageUrl = KrogerLogo;
        displayName = "Kroger";
    } else if (storeName === "MEIJER") {
        imageUrl = MeijerLogo;
        displayName = "Meijer";
    } else if (storeName === "SIMILAC") {
        imageUrl = SimilacLogo;
        displayName = "Similac";
    } else if (storeName === "SAMS_CLUB") {
        imageUrl = SamsClubLogo;
        displayName = "Sam's Club";
    } else {
        return (<></>);
    }
    return (
        <>
        <Grid item minHeight={40}>
            <Paper elevation={1} style={{ border: "1px solid", padding: "0px 8px", display: "flex", alignItems: "center", height: "36px" }}>
                <img src={imageUrl} alt={displayName} height="16" />
            </Paper>
        </Grid>
        </>
    );
  }
  
  export default DisplayStore;