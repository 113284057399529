import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import User from './routes/user';
import InStockReport from './routes/instockreport';
import Home from './routes/home';
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { SnackbarProvider } from 'notistack';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";
import Unsubscribe from './routes/unsubscribe';

const client = new ApolloClient({
  uri: "https://api.findmybabyformula.com/graphql",
  cache: new InMemoryCache(),
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <SnackbarProvider maxSnack={3} autoHideDuration={1000}>
          <Routes>
            <Route path="/" element={<App />}>
              <Route index element={<Home />} />
              <Route path="signup" element={<Home />} />
              <Route path="user/:phoneNumber/:validationCode" element={<User />} />
              <Route path="user/:phoneNumber/:validationCode/unsubscribe" element={<Unsubscribe />} />
              <Route path="instockreport/:phoneNumber/:validationCode/:reportId" element={<InStockReport />} />
              <Route path="stock/:phoneNumber/:validationCode/:reportId" element={<InStockReport />} />
              <Route
                  path="*"
                  element={
                    <main style={{ padding: "1rem" }} classname="font-face-gm">
                      <p>No Baby Formula here! Wrong Link!</p>
                    </main>
                  }
              />
            </Route>
          </Routes>
        </SnackbarProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
);

