import React from "react";
import { Grid } from "@mui/material";
import { useQuery, gql } from "@apollo/client";
import { Card, CardContent, Typography, Box } from "@mui/material";
import DisplayStore from "../components/displayStore";
import Donation from "../components/donation";
import Star1 from "../assets/star1.png";
import Star2 from "../assets/star2.png";
import Star3 from "../assets/star3.png";
import IPhone from "../assets/iphone.png";
import IOSStore from "../assets/ios_store.png";
import GoogleStore from "../assets/google_play.png";

const STATS = gql`
  query stats {
    stores: __type(name: "StoreEnum") {
      name
      enumValues {
        name
      }
    }
  }
`;

function Home() {
  const { data } = useQuery(STATS);
  document.title = "Find My Baby Formula | Home";

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={8}>
          <Grid container fontSize={45} mt={2} fontWeight={900}>
            Being a parent is hard enough!
          </Grid>
          <Grid container fontSize={24} mb={4} sx={{ color: "text.secondary" }}>
            Finding formula shouldn't be.
          </Grid>
          <Grid container mb={4}>
            <Grid
              item
              xs={12}
              md={2}
              style={{
                display: "grid",
                placeContent: "center",
                position: "relative",
              }}
            >
              <img src={Star1} alt="Star 1" />
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography fontFamily={"Zen Maru Gothic"} fontSize={36}>
                Sign Up. &nbsp;
              </Typography>
              <Typography
                fontFamily={"Kalam"}
                fontSize={32}
                fontWeight={700}
                style={{ color: "#D64745", paddingTop: "12px" }}
              >
                (It's free!)
              </Typography>
            </Grid>
          </Grid>
          <Grid container mb={4}>
            <Grid
              item
              xs={12}
              md={2}
              style={{
                display: "grid",
                placeContent: "center",
                position: "relative",
              }}
            >
              <img src={Star2} alt="Star 2" />
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              style={{
                display: "grid",
                position: "left",
                alignItems: "center",
              }}
              fontSize={36}
            >
              Select your store, and the type of formula you need.
              <Grid container columnSpacing={{ xs: 1, sm: 1 }} mt={2}>
                {data &&
                  data.stores &&
                  data.stores.enumValues.map((e) => DisplayStore(e.name))}
              </Grid>
            </Grid>
          </Grid>
          <Grid container mb={4}>
            <Grid
              item
              xs={12}
              md={2}
              style={{
                display: "grid",
                placeContent: "center",
                position: "relative",
              }}
            >
              <img src={Star3} alt="Star 3" />
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              style={{
                display: "grid",
                position: "left",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid item fontSize={36}>
                  Receive a push notification as soon as your formula is in
                  stock.
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          lg
          sx={{
            position: "relative",
            width: "100%",
            height: { xs: "400px", lg: "100%" },
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Box
            component="img"
            src={IPhone}
            alt="iphone"
            height={"100%"}
            sx={{
              position: { xs: "initial", lg: "absolute" },
              top: "0",
              left: "-200px",
              right: "0",
              bottom: "0",
              zIndex: "-1",
            }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          fontSize={18}
          xs={12}
          md={8}
          bgcolor={"white"}
          style={{
            border: "1px solid",
            borderRadius: "4px",
            padding: "0 24px",
          }}
        >
          <p>
            As a new father, this problem is very personal for me. My son needed
            food, and I found myself struggling to find it. Being a programmer,
            I discovered that I could automatically monitor several of my local
            markets, and receive messages when new stock arrived. Because of
            this, I'm now able to maintain a formula supply for my child.
            <br />
            <br />
            After seeing my friends' struggling to find formula on Facebook and
            elsewhere, I decided I wanted to help others use my solution. So I
            spent the weekend building FindMyBabyFormula.com. I hope that by
            providing this service, that many in the USA will get through this
            shortage with a well fed, happy baby!
          </p>
        </Grid>
        <Grid item xs md ml={1} sx={{ marginTop: { xs: "24px", md: "0" } }}>
          <Grid
            container
            columnSpacing={{ xs: 1, sm: 2 }}
            rowSpacing={{ xs: 2, sm: 2 }}
            justifyContent="center"
          >
            <Grid item xs={6}>
              <Card
                style={{
                  border: "1px solid",
                  height: "128px",
                  paddingTop: "5px",
                  boxSizing: "border-box",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Typography
                    textAlign={"center"}
                    fontSize={16}
                    color={"#DA9CCD"}
                  >
                    Signups
                  </Typography>
                  <Typography textAlign={"center"} fontSize={24}>
                    126,238
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                style={{
                  border: "1px solid",
                  height: "128px",
                  paddingTop: "5px",
                  boxSizing: "border-box",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Typography
                    textAlign={"center"}
                    fontSize={16}
                    color={"#D64745"}
                  >
                    Zip Codes
                  </Typography>
                  <Typography textAlign={"center"} fontSize={24}>
                    17,907
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                style={{
                  border: "1px solid",
                  height: "128px",
                  paddingTop: "5px",
                  boxSizing: "border-box",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Typography
                    textAlign={"center"}
                    fontSize={16}
                    color={"#EF936D"}
                  >
                    Texts Sent
                  </Typography>
                  <Typography textAlign={"center"} fontSize={24}>
                    214,323,117
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                style={{
                  border: "1px solid",
                  height: "128px",
                  paddingTop: "5px",
                  boxSizing: "border-box",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Typography
                    textAlign={"center"}
                    fontSize={16}
                    color={"#316D56"}
                  >
                    Products Found
                  </Typography>
                  <Typography textAlign={"center"} fontSize={24}>
                    744,273,626
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Donation />
        </Grid>
      </Grid>
    </>
  );
}

export default Home;
