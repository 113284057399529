import { useQuery, useMutation, gql } from "@apollo/client";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import React from 'react'
import Donation from "../components/donation";

const USER_QUERY = gql`
  query user($phoneNumber: Long!, $validationCode: Short!) {
    user(phoneNumber: $phoneNumber, validationCode: $validationCode) {
        phoneNumber
        zipCode
        isActive
    }
}`;

const DEACTIVATE = gql`
  mutation deactivate($phoneNumber: Long!, $validationCode: Short!, $setActive: Boolean!) { deactivate(phoneNumber: $phoneNumber, validationCode: $validationCode, setActive: $setActive) } 
`


function Unsubscribe() {
    let params = useParams();

    document.title = "Find My Baby Formula | Unsubscribe";

    const { loading, error, data } = useQuery(USER_QUERY, {
        variables: { phoneNumber : parseInt(params.phoneNumber), validationCode : parseInt(params.validationCode) }
      });

    const [
        deactivate
      ] = useMutation(DEACTIVATE, {
        refetchQueries: [{ query: USER_QUERY, variables: { phoneNumber : parseInt(params.phoneNumber), validationCode : parseInt(params.validationCode) } }]
      });

    if (loading) return `loading`;
    if (error) return `Error! ${error}`;

    return (
        <>
        { data.user.isActive &&
            <>
            <Grid container>
                <Grid item fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={18} sx={{ textAlign: "center", width: "100%" }} m={2}>
                    Are you sure you wish to unsubscribe?
                </Grid>
            </Grid>
            <Grid container>
                <Grid item fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={18} sx={{ textAlign: "center", width: "100%" }} m={2}>
                    <Button disabled={!data.user.isActive} variant="contained" onClick={e => deactivate({ variables: { phoneNumber : parseInt(data.user.phoneNumber), validationCode : parseInt(params.validationCode), setActive : false}})}>Unsubscribe</Button>
                </Grid>
            </Grid>
            </>
        }
        { !data.user.isActive &&
            <>
            <Grid container>
                <Grid item fontFamily={"Zen Maru Gothic"} fontWeight={900} fontSize={24} sx={{ textAlign: "center", width: "100%" }} m={2}>
                    All set! I want to thank you so much for using my product. It has been a blessing. I hope the reason for for leaving was because your baby is now well fed.  Feel free to reach out on Facebook with any comments, concerns or questions!
                </Grid>
            </Grid>
            <Grid container>
                <Grid item fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={18} sx={{ textAlign: "center", width: "100%" }} m={2}>
                    The link to your user panel is below. If you want to re-enable your account, you can do so near the bottom past the settings page.
                </Grid>
            </Grid>
            </>
        }
        <Grid container>
          <Grid item fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={18} sx={{ textAlign: "center", width: "100%" }} m={2}>
            <a href={`/user/${parseInt(params.phoneNumber)}/${parseInt(params.validationCode)}`}>Back to your user page.</a>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Donation/>
          </Grid>
        </Grid>
        </>
    );
}

export default Unsubscribe;
