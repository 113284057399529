import React from 'react'
import { Grid, Button } from "@mui/material";
import PinkDollar from '../assets/pink_dollar.png'
import BlueQuestion from '../assets/blue_question.png'
import FacebookLogo from '../assets/facebook_logo.png'
import TwitterLogo from '../assets/twitter_logo.png'
import ABC7News from '../assets/abc7news.png'
import KHouLogo from '../assets/khoulogo.png'
import NJSpotNews from '../assets/njspotnews.png'

function Donation() {    
    return (
        <>
            <Grid container style={{ border: "1px solid", borderRadius: "4px" }} bgcolor={"white"} m={2} minHeight={120} ml={0}>
                <Grid item xs={12} md={1} mt={2} style={{display:'grid', placeContent:"center", position: "relative"}}>
                    <img src={PinkDollar} alt="Pink Dollar"/>
                </Grid>
                <Grid item xs={12} md={7} m={2} fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={18} style={{display:'grid', position: "left", alignItems: "center"}} >
                    Affording a baby is hard. That's why I'll never charge for this tool, ever!
                    However, the servers and bandwidth to maintain this service are not cheap. If you can spare anything to keep this site online, I appreciate it!
                    If you cannot donate, a shoutout on social media is just as helpful, links below!
                </Grid>
                <Grid item xs md style={{display:'grid', placeContent:"center", position: "relative"}} mb={2}>
                    <Button variant='outlined'  fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={18} href="https://www.buymeacoffee.com/kenWD">
                        Buy Me a Coffee
                    </Button>
                    <br></br>
                    <Button variant='outlined'  fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={18} href="https://www.venmo.com/u/kenbeannet">
                        Venmo - @kenbeannet
                    </Button>
                </Grid>
            </Grid>
            <Grid container style={{ border: "1px solid", borderRadius: "4px" }} bgcolor={"white"} minHeight={80} mt={2} ml={0}>
                <Grid item xs md={1} mt={2} style={{display:'grid', placeContent:"center", position: "relative"}}>
                    <img src={BlueQuestion} alt="Question Mark"/>
                </Grid>
                <Grid item xs={12} md={6} ml={2} mb={2} fontFamily={"Zen Maru Gothic"} fontWeight={900} fontSize={18} style={{display:'grid', position: "left", alignItems: "center"}}>
                    Have a suggestion or an issue? Send us an email!
                </Grid>
                <Grid item xs md={4} mb={2} fontFamily={"Zen Maru Gothic"} fontSize={18}  style={{display:'grid', position: "right", alignItems: "center", textAlign:"center"}}>
                    <a href="mailto:admin@findmybabyformula.com">admin@findmybabyformula.com</a> 
                </Grid>
            </Grid>
            <Grid container style={{ border: "1px solid", borderRadius: "4px", display:'grid', placeContent:"center", position: "relative" }} bgcolor={"white"} minHeight={80} mt={2} ml={0} mb={1}>
                <Grid item style={{display:'grid', placeContent:"center", position: "relative", color:"#D64745"}} fontFamily={"Kalam"} fontSize={28} fontWeight={700} >
                    As Seen On
                </Grid>
                <Grid container mt={1} columnSpacing={3} alignItems="center" justify="center" mb={2} ml={1}>
                    <Grid item>
                        <a href="https://www.khou.com/article/news/local/baby-formula-finder/285-7569337c-5722-4673-a210-eaa5c259613b">
                            <img width={160} src={KHouLogo} alt={"KHou"} />
                        </a>
                    </Grid>
                    <Grid item>
                        <a href="https://abc7ny.com/baby-formula-shortage-enfamil-infant/11933132/">
                            <img width={70} src={ABC7News} alt={"ABC7News"} />
                        </a>
                    </Grid>
                    <Grid item>
                        <a href="https://youtu.be/RobBXJy6ihc?t=102">
                            <img width={160} src={NJSpotNews} alt={"NJSpot"} />
                        </a>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container m={2} ml={0} justifyContent="center" columnSpacing={2}>
                <Grid item>
                    <a href="https://www.facebook.com/findmybabyformula">
                        <img width={50} src={FacebookLogo} alt={"Facebook"} />
                    </a>
                </Grid>
                <Grid item>
                    <a href="https://twitter.com/BabyFormulaFind">
                        <img width={50} src={TwitterLogo} alt={"Twitter"} />
                    </a>
                </Grid>
            </Grid>
        </>
    );
  }
  
  export default Donation;