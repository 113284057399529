import React from 'react'
import { Grid, Divider, Button, Typography } from "@mui/material";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import Donation from '../components/donation';
import DisplayStore from '../components/displayStore';

const IN_STOCK_REPORT_QUERY = gql`
  query userInStockReport($phoneNumber: Long!, $validationCode: Short!, $reportId: Int!) {
    userInStockReport(phoneNumber: $phoneNumber, validationCode: $validationCode, reportId: $reportId) {
      storeProduct {
        productId
        productUrl
        product {
          name
          imageUrl
        }
      }
      store
      distance
      address
    } 
}`;

function InStockReport() {
    let params = useParams();

    document.title = "Find My Baby Formula | In Stock Report";

    const { loading, error, data } = useQuery(IN_STOCK_REPORT_QUERY, {
        variables: { phoneNumber : parseInt(params.phoneNumber), validationCode : parseInt(params.validationCode), reportId : parseInt(params.reportId) }
      });
      

    if (loading) return `loading`;
    if (error) return `Error! ${error}`;

    function RenderInStockItem(instockItem)
    {
        return (
            <>
            <Grid container key={instockItem.id} sx={{ marginTop: "16px" }}>
                <Grid item xs={6} md={2}  style={{display:'grid', placeContent:"center", position: "relative"}}>
                  <Grid container style={{display:'grid', placeContent:"center", position: "relative"}}>
                    <Grid item>
                      Store
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      {DisplayStore(instockItem.store)}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={2}  style={{display:'grid', placeContent:"center", position: "relative"}}>
                    <img width={76} src={instockItem.storeProduct.product.imageUrl} alt={instockItem.storeProduct.product.name}/>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Grid container>
                        <Grid item mb={1} fontFamily={"Zen Maru Gothic"} fontWeight={900} fontSize={18} ml={1}>
                            {instockItem.storeProduct.product.name}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item mb={1} fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={16} ml={1}>
                            { instockItem.distance === 0 && 
                              instockItem.address
                            }
                            { instockItem.distance !== 0 && 
                              <a href={`https://maps.google.com/?q=${instockItem.address}`}>{instockItem.address}</a>
                            }
                            
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item mb={1} fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={16} ml={1}>
                          { instockItem.distance !== 0 && 
                              <>{instockItem.distance} mile(s) away</>
                          }
                        </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item ml={1}>
                          { instockItem.distance > 0 && instockItem.store === "WALMART" &&
                            <Typography fontFamily={"Zen Maru Gothic"} fontSize={13} fontWeight={500}>
                               *NOTE* Walmart often allows for purchases online and instore pickup! This method is highly recommended if the store allows it! Not every store does!
                            </Typography> 
                          } 
                          { instockItem.store === "KROGER" &&
                            <Typography fontFamily={"Zen Maru Gothic"} fontSize={13} fontWeight={500}>
                               *NOTE* Kroger and its family stores seem to have some stores with incorrect stock!  Please call ahead before driving long distances. I will only alert if their stock is MORE THAN 10 units.
                            </Typography> 
                          } 
                      </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3} style={{display:'grid', placeContent:"center", position: "relative"}}>
                  <Button target="_blank" href={instockItem.storeProduct.productUrl} variant="outlined" style={{ fontFamily:"Zen Maru Gothic", fontWeight:500 }}>View Online</Button>
                </Grid>
            </Grid>
            <Divider sx={{ width: 'calc(100% - 64px)', margin: '16px 32px 0 32px' }} />
            </>
        )
    }

    return (
        <>
        <Grid container>
          <Grid item fontFamily={"Zen Maru Gothic"} fontWeight={900} fontSize={28} sx={{ textAlign: "center", width: "100%" }} m={2}>
            We are so happy we can help, below is the items in stock!
          </Grid>
        </Grid>
        <Grid container style={{ border: "1px solid" }} bgcolor={"white"}>
          {data.userInStockReport.map(RenderInStockItem)}
        </Grid>
        <Grid container>
          <Grid item>
            <Donation/>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={18} sx={{ textAlign: "center", width: "100%" }} m={2}>
            <a href={`/user/${parseInt(params.phoneNumber)}/${parseInt(params.validationCode)}`}>Back to your user page.</a>
          </Grid>
        </Grid>
        </>
    );
  }
  
  export default InStockReport;