import { useQuery, useMutation, gql } from "@apollo/client";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { Checkbox, Button, FormGroup, FormControlLabel, Divider, Typography, ToggleButton, ToggleButtonGroup, Tooltip, Box, TextField, IconButton } from "@mui/material";
import React, { useState } from 'react'
import Donation from "../components/donation";
import DisplayStore from "../components/displayStore";
import RedWheel from '../assets/red_wheel.png'
import YellowWheel from '../assets/yellow_wheel.png'
import InfoIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import EmailTextField from "../components/emailtextfield";
import IOSStore from '../assets/ios_store.png'
import GoogleStore from '../assets/google_play.png'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
            <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[1000],
            }}
            >
            <CloseIcon />
            </IconButton>
        ) : null}
        </DialogTitle>
    );
};

const USER_QUERY = gql`
  query user($phoneNumber: Long!, $validationCode: Short!) {
    stores: 
    __type(name:"StoreEnum") {
      name
      enumValues{
        name
      }
    }
    products {
        id
        name
        imageUrl
        stores
        isPowder
    }
    user(phoneNumber: $phoneNumber, validationCode: $validationCode) {
        phoneNumber
        zipCode
        isActive
        lastAlertMessageDateTime
        maxDistance
        emailAddress
        isSendDelivery
        isWalmart
        isTarget
        isCVS
        isWalgreens
        isAmazon
        isCostco
        isBuyBuyBaby
        isKroger
        isMeijer
        isSimilac
        isSamsClub
        seenEmailAlert
        token
        reports {
            id
            createdDateTime
        }
        products {
            productId
                product {
                    name
                    imageUrl
            }
        }
    }
}`;
const ADD_USER_PRODUCT = gql`
  mutation addUserProduct($phoneNumber: Long!, $validationCode: Short!, $productId: Short!) { addUserProduct(phoneNumber: $phoneNumber, productId: $productId, validationCode: $validationCode) } 
`
const DEACTIVATE = gql`
  mutation deactivate($phoneNumber: Long!, $validationCode: Short!, $setActive: Boolean!) { deactivate(phoneNumber: $phoneNumber, validationCode: $validationCode, setActive: $setActive) } 
`

const CHANGE_ZIP = gql`
  mutation changeZip($phoneNumber: Long!, $validationCode: Short!, $zipCode: String!) { changeZip(phoneNumber: $phoneNumber, validationCode: $validationCode, zipCode: $zipCode) } 
`
const MUTE_ACCOUNT = gql`
  mutation mute($phoneNumber: Long!, $validationCode: Short!, $unixTimestamp: Long!) { muteAccount(phoneNumber: $phoneNumber, validationCode: $validationCode, unixTimestamp: $unixTimestamp) } 
`
const USER_MAX_DISTANCE = gql`
  mutation userMaxDistance($phoneNumber: Long!, $validationCode: Short!, $maxDistance: Decimal) { userMaxDistance(phoneNumber: $phoneNumber, validationCode: $validationCode, maxDistance: $maxDistance) } 
`

const USER_IS_SEND_DELIVERY = gql`
  mutation userIsSendDelivery($phoneNumber: Long!, $validationCode: Short!, $value: Boolean!) { userIsSendDelivery(phoneNumber: $phoneNumber, validationCode: $validationCode, value: $value) } 
`
const USER_SET_STORE = gql`
  mutation userSetDeliveryStore($phoneNumber: Long!, $validationCode: Short!, $storeType: StoreEnum!, $value: Boolean!) { userSetDeliveryStore(phoneNumber: $phoneNumber, validationCode: $validationCode, storeType: $storeType, value: $value) } 
`
const USER_SET_EMAIL_ALERT = gql`
  mutation userSetEmailAlert($phoneNumber: Long!, $validationCode: Short!) { userSetEmailAlert(phoneNumber: $phoneNumber, validationCode: $validationCode) } 
`

function User() {
    const [powderFilter, setPowderFilter] = useState(0)
    const [maxDistance, setMaxDistance] = useState(999)
    const [zipCode, setZipCode] = useState(999)
    const [welcomeOpen, setWelcomeOpen] = useState(false);
    const [zipCodeChangeButtonActive, setZipCodeChangeButtonActive] = useState(false);
  
    document.title = "Find My Baby Formula | User";

    let params = useParams();

    const { loading, error, data } = useQuery(USER_QUERY, {
        variables: { phoneNumber : parseInt(params.phoneNumber), validationCode : parseInt(params.validationCode) }
      });

    const [
        addUserProduct
      ] = useMutation(ADD_USER_PRODUCT, {
        refetchQueries: [{ query: USER_QUERY, variables: { phoneNumber : parseInt(params.phoneNumber), validationCode : parseInt(params.validationCode) } }]
      });

    const [
        deactivate
      ] = useMutation(DEACTIVATE, {
        refetchQueries: [{ query: USER_QUERY, variables: { phoneNumber : parseInt(params.phoneNumber), validationCode : parseInt(params.validationCode) } }]
      });
    
    const [
        changeZip
      ] = useMutation(CHANGE_ZIP, {
        refetchQueries: [{ query: USER_QUERY, variables: { phoneNumber : parseInt(params.phoneNumber), validationCode : parseInt(params.validationCode) } }]
      });

    const [
        muteAccount
      ] = useMutation(MUTE_ACCOUNT, {
        refetchQueries: [{ query: USER_QUERY, variables: { phoneNumber : parseInt(params.phoneNumber), validationCode : parseInt(params.validationCode) } }]
      });

    const [
        userMaxDistance
      ] = useMutation(USER_MAX_DISTANCE, {
        refetchQueries: [{ query: USER_QUERY, variables: { phoneNumber : parseInt(params.phoneNumber), validationCode : parseInt(params.validationCode) } }]
      });

    const [
        userIsSendDelivery
      ] = useMutation(USER_IS_SEND_DELIVERY, {
        refetchQueries: [{ query: USER_QUERY, variables: { phoneNumber : parseInt(params.phoneNumber), validationCode : parseInt(params.validationCode) } }]
      });

    const [
        userSetDeliveryStore
      ] = useMutation(USER_SET_STORE, {
        refetchQueries: [{ query: USER_QUERY, variables: { phoneNumber : parseInt(params.phoneNumber), validationCode : parseInt(params.validationCode) } }]
      });
    
    const [
        userSetEmailAlert
      ] = useMutation(USER_SET_EMAIL_ALERT, {
        refetchQueries: [{ query: USER_QUERY, variables: { phoneNumber : parseInt(params.phoneNumber), validationCode : parseInt(params.validationCode) } }]
      });

    
    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    function handleUserAddProduct(params)
    {
        handleClickVariant('success');
        addUserProduct(params)
    }

    const { enqueueSnackbar } = useSnackbar();

    function handleZipCode(e)
    {
        setZipCode(e.target.value);
        if (e.target.value === data.user.zipCode) {
            setZipCodeChangeButtonActive(false);
        } else if (e.target.value > 99999) {
            setZipCodeChangeButtonActive(false);
        } else if (e.target.value.length !== 5) {
            setZipCodeChangeButtonActive(false);
        } else {
            setZipCodeChangeButtonActive(true);
        }
    }

    async function handleChangeZip()
    {
        try {
            await changeZip({ variables: { phoneNumber: parseInt(data.user.phoneNumber), validationCode: parseInt(params.validationCode), zipCode: zipCode } });
            handleClickVariant('success');
            setZipCodeChangeButtonActive(false);
        } catch {
            enqueueSnackbar('Error, Wrong Zipcode!', { variant: 'error' });
        }
    }

    function handleMaxDistance(e)
    {
        var distance = "";
        if (e.target.value > 50) {
            distance = 50;
        } else {
            distance = e.target.value
        }
        if (distance.length === 0) {
            setMaxDistance('');
        }
        else if (distance === "0") {
            setMaxDistance('');
        }
        else {
            setMaxDistance(parseInt(distance));
        }
    
        userMaxDistance({ variables: { phoneNumber : parseInt(data.user.phoneNumber), validationCode : parseInt(params.validationCode), maxDistance: parseInt(distance)}})
    }

    if (loading) return `loading`;
    if (error) return `Error! ${error}`;
    
    function RenderProducts(product)
    {
        return (
            <>
            <Grid container sx={{ marginTop: "16px" }}>
                <Grid item xs={4} md={1} style={{display:'grid', placeContent:"center", position: "relative"}}>
                    <FormGroup>
                    <FormControlLabel 
                        control={<Checkbox />}
                        checked={data.user.products.filter(p => p.productId === product.id).length === 1}
                        onClick={e => handleUserAddProduct({ variables: { phoneNumber : parseInt(data.user.phoneNumber), validationCode : parseInt(params.validationCode), productId: parseInt(product.id)}})}
                        label={<Typography fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={10}>Enabled</Typography>}
                        labelPlacement="top"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={8} md={2}  style={{display:'grid', placeContent:"center", position: "relative"}}>
                    <img width={76} src={product.imageUrl} alt={product.name}/>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Grid container>
                        <Grid item mb={1} fontFamily={"Zen Maru Gothic"} fontWeight={900} fontSize={18} ml={1}>
                            {product.name}
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={{ xs: 1, sm: 1 }} rowSpacing={{ xs: 1}}>
                        {product.stores.filter(onlyUnique).map(DisplayStore)}
                    </Grid>
                </Grid>
            </Grid>
            <Divider sx={{ width: 'calc(100% - 64px)', margin: '16px 32px 0 32px' }} />
            </>
        )
    }

    function RenderStoreSelection(store)
    {
        return (
            <Grid item xs={6} md={2} mb={2}>
                <Grid container style={{display:'grid', placeContent:"center", position: "relative"}}>
                    <Grid item xs={12} md={12}>
                        <FormGroup>
                            <FormControlLabel 
                                control={<Checkbox />}
                                checked={FindUserStoreValueByStoreEnum(data.user, store.name)}
                                onClick={e => userSetDeliveryStore({ variables: { phoneNumber : parseInt(data.user.phoneNumber), validationCode : parseInt(params.validationCode), storeType: store.name, value: !FindUserStoreValueByStoreEnum(data.user, store.name) }})}
                                label={<Typography fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={10}>Enabled</Typography>}
                                labelPlacement="top"
                                />
                        </FormGroup>
                    </Grid>
                    <Grid item >
                        {DisplayStore(store.name)}
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    function RenderReport(report)
    {
        return (
            <Grid container key={report.id}>
                <Grid item mb={1}>
                    <a href={`/stock/${parseInt(params.phoneNumber)}/${parseInt(params.validationCode)}/${report.id}`}>
                        Baby Formula In Stock at {new Date(report.createdDateTime * 1000).toLocaleString()} local time!
                    </a>
                </Grid>
            </Grid>
        )
    }

    const handleClickVariant = variant =>  {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar('Success!', { variant });
    };

    function FindUserStoreValueByStoreEnum(user, store)
    {
        if (store === "WALMART")
            return user.isWalmart;
        else if (store === "TARGET")
            return user.isTarget;
        else if (store === "CVS")
            return user.isCVS;
        else if (store === "WALGREENS")
            return user.isWalgreens;
        else if (store === "AMAZON")
            return user.isAmazon;
        else if (store === "COSTCO")
            return user.isCostco;
        else if (store === "BUY_BUY_BABY")
            return user.isBuyBuyBaby;
        else if (store === "KROGER")
            return user.isKroger;
        else if (store === "MEIJER")
            return user.isMeijer;
        else if (store === "SIMILAC")
            return user.isSimilac;
        else if (store === "SAMS_CLUB")
            return user.isSamsClub;
    }

    var orderedItems = [...data.products];
    if (maxDistance === 999 && data && data.user) {
        setMaxDistance(data.user.maxDistance ?? 50);
        if (data.user.token === null || data.user.token.length === 0)
            setWelcomeOpen(true);
        setZipCode(data.user.zipCode);
    }

    const handleWelcomeClose = () => {
        setWelcomeOpen(false);
        userSetEmailAlert({ variables: { phoneNumber : parseInt(data.user.phoneNumber), validationCode : parseInt(params.validationCode) }});
      };

    return (
        <>
        <BootstrapDialog
            onClose={handleWelcomeClose}
            aria-labelledby="customized-dialog-title"
            open={welcomeOpen}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleWelcomeClose} fontFamily={"Zen Maru Gothic"} fontWeight={900} fontSize={24} fontStyle={{ color: "#D64745" }}>
                New App just released!
            </BootstrapDialogTitle>
            <DialogContent dividers>
            <Typography gutterBottom fontFamily={"Zen Maru Gothic"} fontWeight={900} fontSize={16} fontStyle={{ color: "#316D56" }}>
                We are moving to a new notification system.  Below are links to the Apple Store (iPhones) and Google Play Store (Andriod) devices. <br/><br/> The signup process is super simple and takes only a few seconds.  <br /><br /> This will help with our monthly costs and also allow to send unlimited amount of notifications. <br/><br/> Please upgrade by 8/2/2022 to continue to recieve notifications!
            </Typography>
            <Typography gutterBottom>
                <Grid container direction="row" alignItems="center" justifyContent="center" alignContent="center">
                    <Grid item xs={6}>
                        <a href={"https://play.google.com/store/apps/details?id=com.findmybabyformula"}><img src={GoogleStore} alt="Google Play Store" width="140px"/></a>
                    </Grid>
                    <Grid item xs={6}>
                    <a href={"https://apps.apple.com/us/app/findmybabyformula-com/id1635661337"}><img src={IOSStore} alt="IOS Store" width="140px"/></a>
                    </Grid>
                </Grid>
            </Typography>
            </DialogContent>
        </BootstrapDialog>
        { data.user.reports.length > 0 && 
            <>
            <Grid container>
                <Grid item fontFamily={"Zen Maru Gothic"} fontWeight={900} fontSize={24} mt={4} sx={{ textAlign: "center", width: "100%" }}>
                    We have found you in-stock items! Click the reports below!
                </Grid>
            </Grid>
            <Grid container mt={2} mb={2} style={{ display: 'grid', placeContent: "center", position: "relative" }}>
                <Grid item fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={18} mb={1} sx={{ textAlign: "center", width: "100%" }}>
                    {data.user.reports.map(RenderReport)}
                </Grid>
            </Grid>
            </>
        }
        { data.user.reports.length === 0 && 
            <>
            <Grid container>
                <Grid item fontFamily={"Zen Maru Gothic"} fontWeight={900} fontSize={24} mt={4} sx={{ textAlign: "center", width: "100%" }}>
                    Keep this link! Once you get an in-stock text message, your in-stock items will appear below this message.
                </Grid>
            </Grid>
            </>
        }
        
        <Grid container>
            <Grid item fontFamily={"Zen Maru Gothic"} fontWeight={900} fontSize={24} mt={2} mb={4} sx={{ textAlign: "center", width: "100%" }}>
                Enable the items you want to receive alerts for
            </Grid>
        </Grid>
        <Grid container style={{display:'grid', placeContent:"center", position: "relative"}} mb={2}>
            <Grid item>
                <ToggleButtonGroup
                    value={powderFilter}
                    exclusive
                    onChange={e => setPowderFilter(e.target.value) } >
                    <ToggleButton value={0} style={{ outlineColor: 'black', outlineWidth: '1px', outlineStyle: 'solid', fontFamily:"Zen Maru Gothic" }}>All</ToggleButton>
                    <ToggleButton value={1} style={{ outlineColor: 'black', outlineWidth: '1px', outlineStyle: 'solid', fontFamily:"Zen Maru Gothic" }}>Powder</ToggleButton>
                    <ToggleButton value={2} style={{ outlineColor: 'black', outlineWidth: '1px', outlineStyle: 'solid', fontFamily:"Zen Maru Gothic" }}>Liquid</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
        </Grid>
        <Grid container style={{ border: "1px solid" }} bgcolor={"white"}>
            { powderFilter == 0 && 
                orderedItems.sort((a,b) => a.name > b.name ? 1 : -1).map(RenderProducts) 
            }
            { powderFilter == 1 && 
                orderedItems.filter(e => e.isPowder === true).sort((a,b) => a.name > b.name ? 1 : -1).map(RenderProducts) 
            }
            { powderFilter == 2 && 
                orderedItems.filter(e => e.isPowder === false).sort((a,b) => a.name > b.name ? 1 : -1).map(RenderProducts) 
            }
        </Grid>
        <Grid container style={{ border: "1px solid" }} bgcolor={"white"} minHeight={80} mt={3}>
            <Grid item xs md={1} style={{display:'grid', placeContent:"center", position: "relative"}} mt={1}>
                <img src={YellowWheel} alt="Yellow Wheel"/>
            </Grid>
            <Grid item xs={12} md fontFamily={"Zen Maru Gothic"} fontWeight={900} fontSize={18} style={{display:'grid', position: "left", alignItems: "center"}}  ml={1}>
                Stores
            </Grid>
            <Grid item xs md={10} m={1}>
                <Grid container>
                    { data.stores.enumValues.map(RenderStoreSelection) }
                </Grid>
            </Grid>
        </Grid>
        <Grid container style={{ border: "1px solid" }} bgcolor={"white"} mt={2} mb={2}>
            <Grid item xs md={1} style={{display:'grid', placeContent:"center", position: "relative"}}>
                <img src={YellowWheel} alt="Red Wheel"/>
            </Grid>
            <Grid item xs={12} md fontFamily={"Zen Maru Gothic"} fontWeight={900} fontSize={18} style={{display:'grid', position: "left", alignItems: "center"}}  ml={1}>
                Settings
            </Grid>
            <Grid item xs md={8} m={1}>
                <Grid container justifyContent='space-between'>
                { data.user && (!data.user.lastAlertMessageDateTime || (data.user.lastAlertMessageDateTime * 1000) < Date.now()) && 
                    <>
                        <Grid item mb={2} xs={12} md={3}>
                            <Grid container rowSpacing={{ xs: 1, sm: 1 }} >
                                <Grid item xs={12} xl={12} fontWeight={500} fontSize={16} style={{display:'grid', placeContent:"center", position: "relative"}}>
                                    <Tooltip title="If you have secured enough formula, this is a great tool stop alerts for a bit until your stock runs low again. This will mute all messages for 7-14-30 days." enterTouchDelay={0} leaveTouchDelay={12000}>
                                        <IconButton>
                                            <InfoIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} xl={12} fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={14} style={{display:'grid', placeContent:"center", position: "relative"}}>
                                    Disable alerts for
                                </Grid>
                                <Grid item xs={12} xl={12} style={{display:'grid', placeContent:"center", position: "relative"}}>
                                    <Button variant="outlined" style={{ height: 20, fontFamily:"Zen Maru Gothic", fontWeight:500, fontSize:14 }} onClick={e => muteAccount({ variables: { phoneNumber: parseInt(data.user.phoneNumber), validationCode: parseInt(params.validationCode), unixTimestamp: Date.now() + (1000 * 60 * 60 * 24 * 7) } })}>7 days</Button>
                                </Grid>
                                <Grid item xs={12} xl={12} style={{display:'grid', placeContent:"center", position: "relative"}}>
                                    <Button variant="outlined" style={{ height: 20, fontFamily:"Zen Maru Gothic", fontWeight:500, fontSize:14 }} onClick={e => muteAccount({ variables: { phoneNumber: parseInt(data.user.phoneNumber), validationCode: parseInt(params.validationCode), unixTimestamp: Date.now() + (1000 * 60 * 60 * 24 * 14) } })}>14 days</Button>
                                </Grid>
                                <Grid item xs={12} xl={12} style={{display:'grid', placeContent:"center", position: "relative"}}>
                                    <Button variant="outlined" style={{ height: 20, fontFamily:"Zen Maru Gothic", fontWeight:500, fontSize:14 }} onClick={e => muteAccount({ variables: { phoneNumber: parseInt(data.user.phoneNumber), validationCode: parseInt(params.validationCode), unixTimestamp: Date.now() + (1000 * 60 * 60 * 24 * 30) } })}>30 days</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
                { data.user && (data.user.lastAlertMessageDateTime * 1000) > Date.now() && 
                    <>
                    <Grid item mb={2} xs={12} md={3}>
                        <Grid container rowSpacing={{ xs: 1, sm: 1 }} >
                            <Grid item xs={12} xl={12} fontWeight={500} fontSize={14} style={{display:'grid', placeContent:"center", position: "relative"}}>
                                <Tooltip title="Your notifications are currently off. They will resume after the date below." enterTouchDelay={0} leaveTouchDelay={12000}>
                                    <IconButton>
                                        <InfoIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} xl={12} fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={14} style={{display:'grid', placeContent:"center", position: "relative"}}>
                                After {new Date(data.user.lastAlertMessageDateTime * 1000).toLocaleString()}
                            </Grid>
                            <Grid item xs={12} xl={12} fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={14} style={{display:'grid', placeContent:"center", position: "relative"}}>
                                <Button variant="outlined" style={{ height: 30, fontFamily:"Zen Maru Gothic", fontWeight:500, fontSize:16 }} onClick={e => muteAccount({ variables: { phoneNumber: parseInt(data.user.phoneNumber), validationCode: parseInt(params.validationCode), unixTimestamp: Date.now() } })}>Enable Now</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    </>
                }
                    <Grid item style={{display:'grid', placeContent:"center", position: "relative"}} mb={2} xs={12} md={3}>
                        <Grid container rowSpacing={{ xs: 1, sm: 1 }} >
                            <Grid item xs={12} xl={12} fontWeight={500} fontSize={16} style={{display:'grid', placeContent:"center", position: "relative"}}>
                                <Tooltip title="We check 50 miles around your zip code. If you want make that radius smaller, you can do so below by typing the amount of miles your willing to drive. Careful, if you set this too small, you may not get any alerts." enterTouchDelay={0} leaveTouchDelay={12000}>
                                    <IconButton>
                                        <InfoIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} xl={12} fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={14} style={{display:'grid', placeContent:"center", position: "relative"}}>
                                Max Miles to Drive
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <Box fullWidth label="zip" sx={{ color: '#fff' }}>
                                    <TextField
                                        id="maxDistance"
                                        variant="outlined"
                                        value={maxDistance}
                                        onChange={handleMaxDistance}
                                        InputProps={{ style:{ fontFamily:"Zen Maru Gothic", fontWeight:900, fontSize:18, maxWidth:100, textAlign: 'center' } }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} xl={12} fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={10} style={{display:'grid', placeContent:"center", position: "relative"}}>
                                If empty, distance is 50 miles.
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item mb={2} xs={12} md={3}>
                        <Grid container rowSpacing={{ xs: 1, sm: 1 }} >
                            <Grid item xs={12} xl={12} fontWeight={500} fontSize={16} style={{display:'grid', placeContent:"center", position: "relative"}}>
                                <Tooltip title="We have two modes of notifications. Delivery orders and local orders. Delivery orders are where you buy online and it is shipped. Local orders are where you have to go pick it up in the store. If you wish to DISABLE delivery orders, please turn this off." enterTouchDelay={0}  leaveTouchDelay={12000}>
                                    <IconButton>
                                        <InfoIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} xl={12} fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={14} style={{display:'grid', placeContent:"center", position: "relative"}}>
                                Alerts for online orders
                            </Grid>
                            <Grid item xs={12} md={12} style={{display:'grid', placeContent:"center", position: "relative"}}>
                                <FormGroup>
                                    <FormControlLabel 
                                        control={<Checkbox />}
                                        checked={data.user.isSendDelivery}
                                        onClick={e => userIsSendDelivery({ variables: { phoneNumber : parseInt(data.user.phoneNumber), validationCode : parseInt(params.validationCode), value: !data.user.isSendDelivery }})}
                                        label={<Typography fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={10}>Enabled</Typography>}
                                        labelPlacement="top" />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container style={{ border: "1px solid" }} bgcolor={"white"} minHeight={80}>
            <Grid item xs md={1} style={{display:'grid', placeContent:"center", position: "relative"}} mt={1}>
                <img src={RedWheel} alt="Red Wheel"/>
            </Grid>
            <Grid item xs={12} md fontFamily={"Zen Maru Gothic"} fontWeight={900} fontSize={18} style={{display:'grid', position: "left", alignItems: "center"}}  ml={1}>
                Change Your Zip Code!
            </Grid>
            <Grid item xs md={8} m={1}>
                <Grid container rowSpacing={{ xs: 1, sm: 1 }} >
                    <Grid item xs={12} xl={12} fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={14} style={{display:'grid', placeContent:"center", position: "relative"}}>
                        Zip Code
                    </Grid>
                    <Grid xs={12} xl={12} fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={14} style={{display:'grid', placeContent:"center", position: "relative"}}>
                        <Grid item xs={12} textAlign="center" fontFamily={"Zen Maru Gothic"} fontWeight={500} fontSize={14} style={{display:'grid', placeContent:"center", position: "relative"}}>
                            <Box fullWidth label="zip" sx={{ color: '#fff' }}>
                                <TextField
                                    id="zipCodeChange"
                                    variant="outlined"
                                    value={zipCode}
                                    onChange={e => handleZipCode(e)}
                                    InputProps={{ style:{ fontFamily:"Zen Maru Gothic", fontWeight:900, fontSize:18, maxWidth:100, textAlign: 'center' } }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs fontFamily={"Zen Maru Gothic"} fontSize={18} style={{ display: 'grid', position: "right", alignItems: "center" }} ml={1} mb={1} mr={1}>
                            <Button disabled={!zipCodeChangeButtonActive} variant="outlined" onClick={handleChangeZip}>Change</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Donation/>
          </Grid>
        </Grid>
        </>
    );
}

export default User;
