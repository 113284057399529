
import { useMutation, gql } from "@apollo/client";
import { Grid, Box, Button, TextField } from "@mui/material";
import React, { useState } from 'react'

const USER_EMAIL_ADDRESS_BLOCKED = gql`
  mutation userEmailAddressBlocked($phoneNumber: Long!, $zipCode: String!, $emailAddress: String!) { userEmailAddressBlocked(phoneNumber: $phoneNumber, zipCode: $zipCode, emailAddress: $emailAddress) } 
`

const USER_EMAIL_ADDRESS = gql`
  mutation userEmailAddress($phoneNumber: Long!, $validationCode: Short!, $emailAddress: String!) { userEmailAddress(phoneNumber: $phoneNumber, validationCode: $validationCode, emailAddress: $emailAddress) } 
`

function EmailTextField(data) {
    const [emailAddress, setEmailAddress] = useState('')
    const [validEmail, setValidEmailAddress] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)

    const [
        userEmailAddressBlocked
      ] = useMutation(USER_EMAIL_ADDRESS_BLOCKED);
    
    const [
        userEmailAddress
      ] = useMutation(USER_EMAIL_ADDRESS);
      
    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    function handleEmailAddress(e)
    {
        setEmailAddress(e.target.value);
        setValidEmailAddress((validateEmail(e.target.value) || e.target.value.length === 0));
    }

    function handleEmailSubmit()
    {
        if (!data.validationCode)
            userEmailAddressBlocked({ variables: { phoneNumber : parseInt(data.phoneNumber), zipCode : data.zipCode, emailAddress: emailAddress}});
        else
            userEmailAddress({ variables: { phoneNumber : parseInt(data.phoneNumber), validationCode : parseInt(data.validationCode), emailAddress: emailAddress}});
        setIsSubmitted(true);
    }

    return (
        <>
        { !isSubmitted && 
            <>
                <Grid item  style={{ display: 'grid', placeContent: "center", position: "relative" }} fontFamily={"Zen Maru Gothic"} fontSize={20} mb={2}>
                    Email Address
                </Grid>
                <Grid item mb={2}>
                    <Box fullWidth label="zip" sx={{ color: '#fff' }} style={{ display: 'grid', placeContent: "center", position: "relative" }}>
                        <TextField
                            id="emailAddress"
                            variant="outlined"
                            defaultValue={data.emailAddress ?? ''}
                            autoComplete="off"             
                            onChange={handleEmailAddress}
                            InputProps={{ style: { fontFamily: "Zen Maru Gothic", fontWeight: 700, fontSize: 15, minWidth: 300, textAlign: 'center' } }} />
                        <Button disabled={!validEmail} variant="contained" onClick={handleEmailSubmit}>Submit</Button>
                    </Box>
                </Grid>
            </>
        }
        { isSubmitted && emailAddress.length > 0 &&
            <Grid item mb={2} style={{display:'grid', placeContent:"center", position: "relative"}} fontFamily={"Zen Maru Gothic"} fontSize={20}>
                Thank you for submitting your email.  You should get a email from us within 30 seconds with your control panel!
            </Grid>
        }
        { isSubmitted && emailAddress.length === 0 &&
            <Grid item mb={2} style={{display:'grid', placeContent:"center", position: "relative"}} fontFamily={"Zen Maru Gothic"} fontSize={20}>
                You have removed your email address. You will now start getting text messages again.
            </Grid>
        }
        </>
    )
}
export default EmailTextField;